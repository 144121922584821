import { Button, FlexBox, Image, Modal, TextField, theme } from '@rario/shared-components'
import BuyBackCardInfo from 'components/BuyBackCardInfo'
import { BuyBackCardWrapper } from 'components/BuyBackCardInfo/BuyBackWidget.styles'
import { Lineseparator } from 'components/FaqSection/Faq.styles'
import { buyBackModalTypeProps } from 'components/ModalManager/ModalProvider'
import { buybackAmountConfig, Scarcity } from 'constant'
import useCurrencyConversion from 'hooks/useCurrencyConversion'
import { useRouter } from 'next/router'
import React, { Dispatch, Fragment, SetStateAction } from 'react'
import { getCDNUrl } from 'shared-components/utils'

const UnrevealedPacks = ({
  setIsBuyBackModalOpen,
}: {
  setIsBuyBackModalOpen: Dispatch<SetStateAction<boolean | undefined>>
}) => {
  const router = useRouter()
  return (
    <FlexBox
      flexDirection={'column'}
      width={'100%'}
      justifyContent="center"
      padding="12px 18px 25px 18px"
    >
      <FlexBox justifyContent={'center'}>
        <Image
          src={getCDNUrl('/App/images/buyback/unrevealed-pack.png')}
          width={'98px'}
          height="115px"
          name="unrevealed-pack"
        />
      </FlexBox>
      <TextField
        fontFamily={theme.fonts.primary}
        fontWeight="600"
        fontSize={'20px'}
        lineHeight={'24px'}
        alignSelf="center"
        mt="10px"
      >
        Unrevealed Packs!
      </TextField>
      <TextField
        fontFamily={theme.fonts.primary}
        letterSpacing={theme.letterSpacings.primary}
        fontWeight={'400'}
        fontSize={'12px'}
        lineHeight={'18px'}
        color={theme.colors.whites[9]}
        whiteSpace="pre-line"
        textAlign={'center'}
        mt={'10px'}
      >
        You have some unrevealed packs. You need to reveal all packs before proceeding to apply for
        Rario BuyBack.
      </TextField>
      <FlexBox justifyContent={'center'}>
        <Button
          name="REVEAL PACKS NOW"
          variant={'dynamic'}
          colorVariant={'primaryFilled'}
          onClick={() => {
            setIsBuyBackModalOpen(false)
            router.push('/profile/packs')
          }}
          px={'38px'}
          mt="20px"
          fontSize={'16px'}
          cornerIndentation="14px"
        />
      </FlexBox>
    </FlexBox>
  )
}

const CardsPendingCredit = ({
  setIsBuyBackModalOpen,
}: {
  setIsBuyBackModalOpen: Dispatch<SetStateAction<boolean | undefined>>
}) => {
  return (
    <FlexBox
      flexDirection={'column'}
      width={'100%'}
      justifyContent="center"
      padding="12px 18px 25px 18px"
    >
      <FlexBox justifyContent={'center'}>
        <Image
          src={getCDNUrl('/App/images/buyback/card-pending-credit.png')}
          width={'98px'}
          height="115px"
          name="card-pending-credit"
        />
      </FlexBox>
      <TextField
        fontFamily={theme.fonts.primary}
        letterSpacing={theme.letterSpacings.primary}
        fontWeight={'400'}
        fontSize={'12px'}
        lineHeight={'18px'}
        color={theme.colors.whites[9]}
        whiteSpace="pre-line"
        textAlign={'center'}
        mt={'10px'}
      >
        Your revealed cards are yet to be credited to your Vault. You should be able to see your
        cards in your vault within 24 hours.
      </TextField>
      <FlexBox justifyContent={'center'}>
        <Button
          name="UNDERSTOOD"
          variant={'dynamic'}
          colorVariant={'primaryFilled'}
          onClick={() => {
            setIsBuyBackModalOpen(false)
          }}
          px={'38px'}
          mt="20px"
          fontSize={'16px'}
          cornerIndentation="14px"
        />
      </FlexBox>
    </FlexBox>
  )
}

const InfoBuyBack = () => {
  return (
    <FlexBox
      flexDirection={'column'}
      width={'100%'}
      justifyContent="center"
      padding="12px 18px 25px 18px"
    >
      <TextField
        fontFamily={theme.fonts.primary}
        fontWeight="600"
        fontSize={'20px'}
        lineHeight={'24px'}
        alignSelf="center"
        mb="26px"
      >
        Rario BuyBack
      </TextField>
      <TextField
        fontFamily={theme.fonts.primary}
        letterSpacing={theme.letterSpacings.primary}
        fontWeight={'400'}
        fontSize={'12px'}
        lineHeight={'18px'}
        color={theme.colors.whites[9]}
        whiteSpace="pre-line"
      >
        Rario Buyback is here! You can now participate in the Buyback with all your holdings and
        receive value based on the offering listed below.
        {'\n\n'} Conversion value: $1 = ₹83
        {'\n\n'}
      </TextField>

      <BuyBackCardWrapper
        flexDirection="column"
        padding="16px"
        borderRadius="6px"
        border="1px solid #2e2e2f"
        background="rgba(255, 255, 255, 0.04)"
      >
        {Object.values(Scarcity).map((tier, i) => {
          const [formattedSalePriceINR] = useCurrencyConversion(buybackAmountConfig.card[tier])
          return (
            <Fragment key={tier}>
              <BuyBackCardInfo
                name={tier + ' Cards & Moments'}
                priceInUSD={buybackAmountConfig.card[tier]}
                priceInINR={formattedSalePriceINR}
                tier={tier}
              />
              {Object.values(Scarcity).length !== i + 1 && (
                <FlexBox my="14px">
                  <Lineseparator />
                </FlexBox>
              )}
            </Fragment>
          )
        })}
      </BuyBackCardWrapper>

      <TextField
        fontFamily={theme.fonts.primary}
        letterSpacing={theme.letterSpacings.primary}
        fontWeight={'400'}
        fontSize={'12px'}
        lineHeight={'18px'}
        color={theme.colors.whites[9]}
        whiteSpace="pre-line"
      >
        {'\n\n'}All your holdings including Cards, Moments & Fan Badges will be bought back once you
        participate in the Rario Buyback.
      </TextField>
    </FlexBox>
  )
}

const D3ClosingInfo = () => {
  return (
    <FlexBox mt={'10px'} mb={'5px'} mx={10}>
      <FlexBox flexDirection={'column'} justifyContent="center">
        <TextField
          fontFamily={theme.fonts.primary}
          fontWeight="700"
          fontSize={'16px'}
          lineHeight={'20px'}
          color={theme.colors.footerBG}
          textAlign={'center'}
          mb={20}
        >
          D3 Closing Soon!
        </TextField>
        <TextField
          fontFamily={theme.fonts.primary}
          letterSpacing={theme.letterSpacings.primary}
          fontWeight={'400'}
          fontSize={'12px'}
          lineHeight={'16px'}
          color={theme.colors.whites[7]}
        >
          The final D3 game is scheduled for November 6th, featuring a showdown between Hobart W vs
          Sydney W.
        </TextField>
      </FlexBox>
    </FlexBox>
  )
}

const MarketplaceClosingInfo = () => {
  return (
    <FlexBox mt={'10px'} mb={'5px'} mx={10}>
      <FlexBox flexDirection={'column'} justifyContent="center">
        <TextField
          fontFamily={theme.fonts.primary}
          fontWeight="700"
          fontSize={'16px'}
          lineHeight={'20px'}
          color={theme.colors.footerBG}
          textAlign={'center'}
          mb={20}
        >
          Marketplace Closing Soon!
        </TextField>
        <TextField
          fontFamily={theme.fonts.primary}
          letterSpacing={theme.letterSpacings.primary}
          fontWeight={'400'}
          fontSize={'12px'}
          lineHeight={'16px'}
          color={theme.colors.whites[7]}
          mb={3}
        >
          We will be concluding our marketplace operations on November 6th, 2024. Before the
          marketplace closes, you can delist, edit, or sell any of your listed cards. After the
          closure, all listed cards will be automatically delisted.
        </TextField>
        <TextField
          fontFamily={theme.fonts.primary}
          letterSpacing={theme.letterSpacings.primary}
          fontWeight={'400'}
          fontSize={'12px'}
          lineHeight={'16px'}
          color={theme.colors.whites[7]}
          mb={3}
        >
          Your cards will remain accessible in your vault section. You’ll also have the option to
          participate in Rario's Buyback program, which will allow you to give your cards back to
          Rario for the defined value once the buyback is live.
        </TextField>
        <TextField
          fontFamily={theme.fonts.primary}
          letterSpacing={theme.letterSpacings.primary}
          fontWeight={'400'}
          fontSize={'12px'}
          lineHeight={'16px'}
          color={theme.colors.whites[7]}
        >
          Thank you for your support and the love you’ve shown for Rario!
        </TextField>
      </FlexBox>
    </FlexBox>
  )
}

const FanbadgesClosingInfo = () => {
  return (
    <FlexBox mt={'10px'} mb={'5px'} mx={10}>
      <FlexBox flexDirection={'column'} justifyContent="center">
        <TextField
          fontFamily={theme.fonts.primary}
          fontWeight="700"
          fontSize={'16px'}
          lineHeight={'20px'}
          color={theme.colors.footerBG}
          textAlign={'center'}
          mb={20}
        >
          Fan Badges
        </TextField>
        <FlexBox height={300}>
          <TextField
            fontFamily={theme.fonts.primary}
            letterSpacing={theme.letterSpacings.primary}
            fontWeight={'400'}
            fontSize={'12px'}
            lineHeight={'16px'}
            color={theme.colors.whites[7]}
            mb={3}
          >
            Content explaining why fan badges will be gone with buyback and its value has been
            claimed already.
          </TextField>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}

const BuyBackModal = ({
  isBuyBackModalOpen,
  setIsBuyBackModalOpen,
  buyBackModalType,
  setBuyBackModalType,
}: {
  isBuyBackModalOpen?: boolean
  setIsBuyBackModalOpen: Dispatch<SetStateAction<boolean | undefined>>
  setBuyBackModalType: Dispatch<SetStateAction<buyBackModalTypeProps>>
  buyBackModalType?: buyBackModalTypeProps
}) => {
  return (
    <Modal
      visible={isBuyBackModalOpen}
      variant="fullScreen"
      modalPosition={'flex-end'}
      onClick={() => {
        setIsBuyBackModalOpen(false)
        setBuyBackModalType('info')
      }}
      background="radial-gradient(90.45% 119.66% at 118.8% -4.27%, rgba(205, 65, 58, 0.50) 0%, rgba(205, 65, 58, 0.00) 100%), rgba(255, 255, 255, 0.05)"
      backdropFilter={20}
      desktopRight="5%"
      desktopTop="5%"
      top="5%"
      right="5%"
    >
      {buyBackModalType === 'info' && <InfoBuyBack />}
      {buyBackModalType === 'd3ClosingInfo' && <D3ClosingInfo />}
      {buyBackModalType === 'marketplaceClosingInfo' && <MarketplaceClosingInfo />}
      {buyBackModalType === 'UnrevealedPacks' && (
        <UnrevealedPacks setIsBuyBackModalOpen={setIsBuyBackModalOpen} />
      )}
      {buyBackModalType === 'TokenCreditPendingError' && (
        <CardsPendingCredit setIsBuyBackModalOpen={setIsBuyBackModalOpen} />
      )}
      {buyBackModalType === 'fanBadgesClosingInfo' && <FanbadgesClosingInfo />}
    </Modal>
  )
}

export default BuyBackModal
